<template>
  <v-card>
    <v-toolbar dense flat class="header">
      <v-toolbar-title> {{ $t("stock.image") }}</v-toolbar-title>
    </v-toolbar>
    <v-img
      v-bind:src="singleItem.data.images[0]"
      contain
      style="max-height: 200px"
      class="ma-2"
      v-if="
        singleItem.data &&
        singleItem.data.images &&
        singleItem.data.images.length > 0
      "
    ></v-img>
    <v-mdi
      v-else
      name="mdi-image-off"
      style="max-height: 200px; min-height: 138px; width: 100%"
    ></v-mdi>
  </v-card>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "ProductImage",
  computed: {
    ...mapState("stock", ["singleItem", "sku"]),
  },
};
</script>
