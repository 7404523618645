<template>
  <v-container fluid>
    <Header>
      <template v-slot:title>
        {{ $t("stock.title") }}
      </template>
    </Header>
    <v-row>
      <v-col cols="12">
        <v-card>
          <v-autocomplete
            class="pt-4"
            v-model="model"
            auto-select-first
            v-bind:label="$t('stock.searchEan')"
            :search-input.sync="search"
            :items="items"
            no-filter
            :menu-props="{ closeOnContentClick: true }"
            style="padding: 0 16px"
            hide-no-data
          >
            <template v-slot:item="{ item }">
              <v-list-item @click="getSkuData(item.sku)">
                {{ item.ean }} ({{ item.sku }}) {{ item.upc }}
              </v-list-item>
            </template>
          </v-autocomplete>
        </v-card>
      </v-col>
    </v-row>
    <v-row v-if="sku">
      <v-col cols="12">
        <StockInfo />
      </v-col>
    </v-row>
    <v-row v-if="sku" style="display: flex">
      <v-col cols="8" style="flex: 1">
        <ProductInfo />
      </v-col>
      <v-col cols="4" style="flex: 1">
        <ProductImage />
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import { mapState, mapActions } from "vuex";
import StockInfo from "@/components/stock/StockInfo";
import ProductInfo from "@/components/stock/ProductInfo";
import ProductImage from "@/components/stock/ProductImage";
export default {
  name: "StockOverview",
  components: { ProductImage, StockInfo, ProductInfo },
  data: () => ({
    model: null,
    search: null,
  }),
  mounted() {
    this.fetchItems();
  },
  watch: {
    search(val) {
      this.setSearch(val).then(() => {
        if (val === null) {
          return;
        }
        this.fetchItems(true);
      });
    },
  },
  methods: {
    ...mapActions("stock", [
      "fetchItems",
      "fetchProductInfoBySku",
      "setSku",
      "fetchStockBySku",
      "setSearch",
    ]),
    getSkuData(sku) {
      this.setSku(sku).then(() => {
        this.fetchProductInfoBySku(true);
      });
    },
  },
  computed: {
    ...mapState("stock", ["items", "singleItem", "sku", "stockItems", "sizes"]),
  },
};
</script>
