<template>
  <v-card>
    <v-toolbar dense flat class="header">
      <v-toolbar-title>{{ sku.split("-", 2).join("-") }}</v-toolbar-title>
    </v-toolbar>
    <template>
      <v-list-item color="primary">
        <v-list-item-content>
          <v-list-item-title> {{ $t("stock.name") }}: </v-list-item-title>
        </v-list-item-content>
        <v-list-item-content v-if="singleItem.data && singleItem.data.name">
          {{ singleItem.data.name }}
        </v-list-item-content>
        <v-list-item-content v-else>
          {{ $t("stock.noData") }}
        </v-list-item-content>
      </v-list-item>
      <v-list-item color="primary">
        <v-list-item-content>
          <v-list-item-title> SKU: </v-list-item-title>
        </v-list-item-content>
        <v-list-item-content>
          {{ singleItem.sku }}
        </v-list-item-content>
      </v-list-item>
      <v-list-item color="primary">
        <v-list-item-content>
          <v-list-item-title> Ean: </v-list-item-title>
        </v-list-item-content>
        <v-list-item-content>
          {{ singleItem.ean }}
        </v-list-item-content>
      </v-list-item>
    </template>
  </v-card>
</template>
<script>
import { mapState, mapActions } from "vuex";

export default {
  name: "ProductInfo",
  data() {
    return {
      headers: [
        { text: "Store", value: "location.nameAndCode" },
        { text: "Qty", value: "qty" },
      ],
    };
  },
  computed: {
    ...mapState("stock", ["singleItem", "sku"]),
  },
  methods: {
    ...mapActions("stock", [
      "setSku",
      "fetchProductInfoBySku",
      "fetchStockBySku",
    ]),
    getSkuData(sku) {
      this.setSku(sku).then(() => {
        this.fetchProductInfoBySku(true);
        this.fetchStockBySku(true);
      });
    },
  },
};
</script>
