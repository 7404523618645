<template>
  <v-card>
    <v-toolbar dense flat class="header">
      <v-toolbar-title>{{ sku.split("-", 2).join("-") }}</v-toolbar-title>
      <v-spacer></v-spacer>
      <template
        v-if="
          singleItem.data &&
          singleItem.data.colors &&
          singleItem.data.colors.length > 0
        "
      >
        <v-btn
          color="primary"
          v-bind:key="index"
          v-for="(colors, index) in singleItem.data.colors"
          style="max-width: 50px; margin-right: 5px"
          @click="getSkuData(colors.sku)"
        >
          {{ colors.color }}
        </v-btn>
      </template>
    </v-toolbar>
    <v-data-table
      :headers="headers"
      :items="tableItems"
      loading-text="Loading... Please wait"
      disable-pagination
      disable-items-per-page
      hide-default-footer
    >
      <template
        v-for="row in skuRows"
        v-slot:[`item.${row}`]="{ item, header }"
      >
        {{ quantity(item.skus, header.value) }}
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";

export default {
  name: "StockInfo",
  computed: {
    ...mapState("stock", [
      "sku",
      "tableItems",
      "singleItem",
      "sizes",
      "headers",
    ]),
    ...mapGetters("stock", ["skuRows"]),
  },
  methods: {
    ...mapActions("stock", [
      "setSku",
      "fetchProductInfoBySku",
      "fetchStockBySku",
    ]),
    quantity(skus, sku) {
      let item = skus.filter(function (itemSku) {
        return itemSku.sku.sku === sku;
      });
      if (item.length > 0) {
        return item[0].qty;
      }
      return 0;
    },
    getSkuData(sku) {
      this.setSku(sku).then(() => {
        this.fetchProductInfoBySku(true);
        this.fetchStockBySku(true);
      });
    },
  },
};
</script>
